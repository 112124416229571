<template>
  <div class="step-box">
    <div class="step-number" :class="{current: current === 1}">1</div>
    <div class="step-title" :class="{current: current === 1}">选择广告平台</div>
    <div class="step-dot-box" :class="{current: current === 2}">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <div class="step-number" :class="{current: current === 2}">2</div>
    <div class="step-title" :class="{current: current === 2}">添加广告源</div>
    <div class="step-dot-box" :class="{current: current === 2}">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <div class="step-number" :class="{current: current === 3}">3</div>
    <div class="step-title" :class="{current: current === 3}">选择运营方式</div>
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    current: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="less" scoped>
@defaultColor: #aaa;
@activeColor: @primary-color;
.step-box {
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 25px;
  .step-number{
    width: 16px;
    height: 16px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background-color: @defaultColor;
    line-height: 16px;
    color: #fff;
    margin: 0 5px;
    &.current{
      background-color: @activeColor;
    }
  }
  .step-title{
    font-size: 14px;
    line-height: 16px;
    color: @defaultColor;
    margin: 0 5px;
    font-weight: 500;
    &.current{
      color: @activeColor;
    }
  }
  .step-dot-box{
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.current .dot{
      background-color: @activeColor;
    }
    .dot{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0 2px;
      background-color: @defaultColor;
    }
  }
}
</style>
