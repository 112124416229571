var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"maskClosable":false,"title":_vm.isGuidan ? '自动运营向导-选择广告平台' : '添加广告源向导-选择广告平台',"width":"900px"},on:{"cancel":_vm.handleCancel},model:{value:(_vm.firstModalVisible),callback:function ($$v) {_vm.firstModalVisible=$$v},expression:"firstModalVisible"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"submit",attrs:{"type":"primary"},on:{"click":_vm.handleNext}},[_vm._v(" 下一步 ")])],1),_c('div',{staticClass:"guidence-first-container"},[(_vm.isGuidan)?_c('Step',{attrs:{"current":1}}):_vm._e(),_c('div',{staticClass:"desc"}),_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.formquery}},[(_vm.isGuidan)?_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v("请选择广告平台进行配置（可多选）")]):_vm._e(),(_vm.isGuidan)?_c('div',{staticClass:"guide-plat-list"},_vm._l((_vm.formquery.guidePlatList),function(item,index){return _c('div',{key:index,staticClass:"plat-item-wrapper"},[_c('div',{staticClass:"plat-item",class:{ active: _vm.platIds.includes(item.id) },on:{"click":function($event){return _vm.handleClickPlat(item.id)}}},[_c('img',{attrs:{"src":item.url,"alt":""}}),(_vm.platIds.includes(item.id))?_c('div',{staticClass:"checked-box"}):_vm._e()]),(_vm.platIds.includes(item.id))?_c('a-form-model-item',{staticClass:"guide-form-item",attrs:{"label":"APPID","labelCol":{ span: 8, offset: 0 },"prop":`guidePlatList[${index}].platAppId`,"rules":[
              { required: true, message: '请输入应用ID', trigger: 'blur' },
              {
                required: true,
                validator: (rule, value, callback) => {
                  _vm.validatePlatAppId(rule, value, callback, item)
                }
              }
            ]}},[_c('a-input',{attrs:{"placeholder":"请输入应用ID"},on:{"blur":_vm.handleBlurPlatAppId},model:{value:(item.platAppId),callback:function ($$v) {_vm.$set(item, "platAppId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.platAppId"}})],1):_vm._e()],1)}),0):_c('div',{staticClass:"edit-plat-container"},[_c('div',{staticClass:"head"},[_c('span',[_vm._v("请选择要使用的广告平台")]),_c('div',{staticClass:"plat-select-box"},[_c('span',[_vm._v("已选广告平台")]),_c('m-select-more',{staticStyle:{"width":"260px","margin-left":"15px"},attrs:{"allData":_vm.platList,"searchById":true,"hasIcon":false,"showId":false,"width":"450px","label":"广告平台","showLabel":false},on:{"change":_vm.getEditList},model:{value:(_vm.platIds),callback:function ($$v) {_vm.platIds=$$v},expression:"platIds"}})],1)]),_c('div',{staticClass:"render-plat-list"},_vm._l((_vm.formquery.editPlatList),function(item,index){return _c('div',{key:item.id,staticClass:"plat-item"},[_c('div',{staticClass:"logo-box"},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(item.name))])]),_c('a-form-model-item',{staticClass:"edit-form-item",attrs:{"label":`APPID`,"labelCol":{ span: 7, offset: 0 },"prop":`editPlatList[${index}].platAppId`,"rules":[
                { required: true, message: '请输入应用ID', trigger: 'blur' },
                {
                  required: true,
                  validator: (rule, value, callback) => {
                    _vm.validatePlatAppId(rule, value, callback, item)
                  }
                }
              ]}},[_c('a-input',{attrs:{"placeholder":"请输入应用ID"},on:{"blur":_vm.handleBlurPlatAppId},model:{value:(item.platAppId),callback:function ($$v) {_vm.$set(item, "platAppId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.platAppId"}})],1),_c('div',{staticClass:"close-box",on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteEditItem(index)}}},[_c('a-icon',{attrs:{"type":"minus-circle","theme":"filled"}})],1)],1)}),0)])])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }