<template>
  <a-modal
    :maskClosable="false"
    v-model="firstModalVisible"
    :title="isGuidan ? '自动运营向导-选择广告平台' : '添加广告源向导-选择广告平台'"
    @cancel="handleCancel"
    width="900px"
  >
    <template slot="footer">
      <a-button key="submit" type="primary" @click="handleNext"> 下一步 </a-button>
    </template>
    <div class="guidence-first-container">
      <!-- 步骤条 -->
      <Step :current="1" v-if="isGuidan" />
      <div class="desc"></div>
      <a-form-model ref="ruleForm" :model="formquery">
        <!-- 广告平台列表 -->
        <span v-if="isGuidan" style="margin-left: 20px">请选择广告平台进行配置（可多选）</span>
        <div class="guide-plat-list" v-if="isGuidan">
          <div class="plat-item-wrapper" v-for="(item, index) in formquery.guidePlatList" :key="index">
            <div class="plat-item" @click="handleClickPlat(item.id)" :class="{ active: platIds.includes(item.id) }">
              <img :src="item.url" alt="" />
              <div class="checked-box" v-if="platIds.includes(item.id)"></div>
            </div>
            <a-form-model-item
              label="APPID"
              v-if="platIds.includes(item.id)"
              :labelCol="{ span: 8, offset: 0 }"
              class="guide-form-item"
              :prop="`guidePlatList[${index}].platAppId`"
              :rules="[
                { required: true, message: '请输入应用ID', trigger: 'blur' },
                {
                  required: true,
                  validator: (rule, value, callback) => {
                    validatePlatAppId(rule, value, callback, item)
                  }
                }
              ]"
            >
              <a-input @blur="handleBlurPlatAppId" placeholder="请输入应用ID" v-model.trim="item.platAppId"></a-input>
            </a-form-model-item>
          </div>
        </div>
        <div class="edit-plat-container" v-else>
          <div class="head">
            <span>请选择要使用的广告平台</span>
            <div class="plat-select-box">
              <span>已选广告平台</span>
              <m-select-more
                style="width: 260px; margin-left: 15px"
                v-model="platIds"
                :allData="platList"
                :searchById="true"
                :hasIcon="false"
                :showId="false"
                width="450px"
                label="广告平台"
                :showLabel="false"
                @change="getEditList"
              />
            </div>
          </div>
          <div class="render-plat-list">
            <div class="plat-item" v-for="(item, index) in formquery.editPlatList" :key="item.id">
              <div class="logo-box">
                <img :src="item.icon" alt="" />
                <span>{{ item.name }}</span>
              </div>
              <a-form-model-item
                :label="`APPID`"
                :labelCol="{ span: 7, offset: 0 }"
                class="edit-form-item"
                :prop="`editPlatList[${index}].platAppId`"
                :rules="[
                  { required: true, message: '请输入应用ID', trigger: 'blur' },
                  {
                    required: true,
                    validator: (rule, value, callback) => {
                      validatePlatAppId(rule, value, callback, item)
                    }
                  }
                ]"
              >
                <a-input @blur="handleBlurPlatAppId" placeholder="请输入应用ID" v-model.trim="item.platAppId"></a-input>
              </a-form-model-item>
              <div class="close-box" @click.stop="handleDeleteEditItem(index)">
                <a-icon type="minus-circle" theme="filled" />
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import Step from './Step.vue'
import {
  platForAutoOperator,
  platAccountForAutoOperator,
  getSourceCount
  // postAutoCreatePlat
} from '@/api/aggregateGuide'
import { appBindPlatForAutoOperator, updatePlatAppIdForAutoOperator } from '@/api/aggregate'
export default {
  components: { Step },
  data () {
    return {
      formquery: {
        guidePlatList: [
          {
            name: '穿山甲',
            id: '1',
            platAppId: undefined,
            oldPlatAppId: undefined,
            platAccountId: undefined,
            isFailed: false,
            url: require('@/assets/images/aggregate/chuanshanjia.png')
          },
          {
            name: '优量汇',
            id: '2',
            platAppId: undefined,
            oldPlatAppId: undefined,
            platAccountId: undefined,
            isFailed: false,
            url: require('@/assets/images/aggregate/youlianghui.png')
          },
          {
            name: '快手',
            id: '3',
            platAppId: undefined,
            oldPlatAppId: undefined,
            platAccountId: undefined,
            isFailed: false,
            url: require('@/assets/images/aggregate/kuaishou.png')
          },
          {
            name: '百度',
            id: '6',
            platAppId: undefined,
            oldPlatAppId: undefined,
            platAccountId: undefined,
            isFailed: false,
            url: require('@/assets/images/aggregate/baidu.png')
          }
        ],
        editPlatList: []
      },
      platIds: [],
      platList: [],
      // 各个平台的默认账户信息
      defaultAccountList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isGuidan: {
      default: false,
      type: Boolean
    },
    query: {
      default: () => ({}),
      type: Object
    },
    returnIds: {
      default: () => ([]),
      type: Array
    }
  },
  watch: {
    visible: {
      async handler (val) {
        if (val) {
          this.formquery.editPlatList = []
          await this.platAccountForAutoOperator()
          if (this.isGuidan) {
            if (this.returnIds.length) {
              this.platIds = [...this.returnIds]
            } else {
              this.platIds = ['1']
            }
            this.formquery.guidePlatList.forEach((item) => {
              const { account = {} } = this.defaultAccountList.find((account) => +account.platId === +item.id)
              item.platAppId = account.platAppId || undefined
              item.oldPlatAppId = account.platAppId || undefined
              item.platAccountId = account.id || undefined
              item.isFailed = false
            })
          } else {
            if (this.returnIds.length) {
              this.platIds = [...this.returnIds]
            } else {
              await this.getPlatIds()
            }
            this.getEditList()
          }
        }
      }
    }
  },
  created () {
    this.getPlatList()
  },
  computed: {
    firstModalVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    // 失焦事件，将之前所有的failed都重置
    handleBlurPlatAppId () {
      this.formquery.guidePlatList.forEach((item) => {
        item.isFailed = false
      })
      this.formquery.editPlatList.forEach((item) => {
        item.isFailed = false
      })
    },
    // 针对未完成的平台验证提示
    validatePlatAppId (rule, value, callback, item) {
      if (item.isFailed) {
        return callback(new Error('修改失败！'))
      } else {
        return callback()
      }
    },
    // 编辑状态下惦记按钮删除单个平台
    handleDeleteEditItem (index) {
      const item = this.formquery.editPlatList.splice(index, 1)[0]
      this.platIds = this.platIds.filter((id) => +id !== +item.id)
    },
    // 编辑状态下，获取表单列表
    getEditList () {
      const oldEditList = this.formquery.editPlatList
      this.formquery.editPlatList = []
      this.platIds.forEach((item) => {
        const platInfo = oldEditList.find((plat) => +plat.id === +item)
        let obj
        if (platInfo) {
          obj = platInfo
        } else {
          const p = this.platList.find((plat) => +plat.id === +item)
          const { account = {}, id } = this.defaultAccountList.find((account) => +account.platId === +p.id)
          obj = {
            id: p.id,
            name: p.name,
            icon: p.icon,
            platAppId: account.platAppId,
            oldPlatAppId: account.platAppId,
            platAccountId: id,
            isFailed: false
          }
        }
        this.$set(this.formquery.editPlatList, this.formquery.editPlatList.length, obj)
      })
    },
    // 编辑状态下，获取该广告位之前配置过的平台
    async getPlatIds () {
      const { data = [] } = await getSourceCount({
        placeId: this.query.placeId
      })
      this.platIds = data.map((item) => '' + item.platId)
    },
    // 获取所有平台的默认账户信息
    async platAccountForAutoOperator () {
      const { data = [] } = await platAccountForAutoOperator({
        appId: this.query.appId
      })
      this.defaultAccountList = data
    },
    // 获取广告平台列表
    async getPlatList () {
      const { data = [] } = await platForAutoOperator({
        type: 2
      })
      data.forEach((item) => {
        item.id = '' + item.id
      })
      this.platList = data
    },
    handleCancel () {},
    // 提交全部表单按钮事件
    handleNext () {
      if (this.platIds.length === 0) {
        return this.$message.warning('至少选择一个广告平台')
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let plats = []
          if (this.isGuidan) {
            // 引导
            // 获取选中的广告平台
            const selectPlats = this.formquery.guidePlatList.filter((item) => this.platIds.includes('' + item.id))
            // 获取选中的广告平台下的所有账户
            plats = selectPlats.map((plat) => {
              return {
                platId: plat.id,
                platAppId: plat.platAppId,
                oldPlatAppId: plat.oldPlatAppId,
                platAccountId: plat.platAccountId
              }
            })
          } else {
            // 修改状态
            // 获取选中的广告平台
            // 获取选中的广告平台下的所有账户的信息
            plats = this.formquery.editPlatList.map((plat) => {
              return {
                platId: plat.id,
                platAppId: plat.platAppId,
                oldPlatAppId: plat.oldPlatAppId,
                platAccountId: plat.platAccountId
              }
            })
          }
          this.handleBatchBind(plats)
        }
      })
    },
    // 批量绑定应用ID
    handleBatchBind (plats) {
      // 创建promise数组
      const promiseBind = []
      // 批量发送绑定请求
      plats.forEach((item) => {
        const premiseItem = appBindPlatForAutoOperator({
          ...item,
          appId: this.query.appId,
          placeId: this.query.placeId,
          // 0 添加 1 修改
          type: item.oldPlatAppId ? 1 : 0
        })
        promiseBind.push(premiseItem)
      })
      // 处理批量异步
      Promise.all(promiseBind).then((results) => {
        if (results.every((item) => item.code === 200 && item.msg === 'success')) {
          // 全部绑定完成，进行批量修改
          this.handleBatchEdit(plats)
        } else {
          // 有部分表单未通过绑定
          this.handleBatchFailed(results, plats)
        }
      })
    },
    handleBatchEdit (plats) {
      // 创建promise数组
      const promiseEdit = []
      // 批量发送修改请求
      plats.forEach((item) => {
        const premiseItem = updatePlatAppIdForAutoOperator({
          appId: this.query.appId,
          placeId: this.query.placeId,
          platAccountId: item.platAccountId,
          platAppId: item.platAppId
        })
        promiseEdit.push(premiseItem)
      })
      // 处理批量异步
      Promise.all(promiseEdit).then((results) => {
        if (results.every((item) => item.code === 200 && item.msg === 'success')) {
          // 全部编辑完成
          this.$emit('modalNext1', this.platIds)
        } else {
          // 有部分表单未完成编辑
          this.handleBatchFailed(results, plats)
        }
      })
    },
    handleBatchFailed (results, plats) {
      // 给失败的表单添加失败标识，该标识会在表单的失焦事件中重置掉
      this.$message.error('部分表单未修改成功！')
      results.forEach((res, index) => {
        let plat
        // platId
        const pId = plats[index].platId
        // 拿到该plat表单对象
        if (this.isGuidan) {
          plat = this.formquery.guidePlatList.find((item) => +item.id === +pId)
        } else {
          plat = this.formquery.editPlatList.find((item) => +item.id === +pId)
        }
        // 设置失败标志
        plat.isFailed = res.msg !== 'success'
      })
      // 触发失败验证
      this.$refs.ruleForm.validate(() => {})
    },
    handleClickPlat (id) {
      if (this.platIds.includes(id)) {
        // 已选中
        this.platIds = this.platIds.filter((item) => item !== id)
      } else {
        // 未选中
        this.platIds.push(id)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.guidence-first-container {
  .desc {
    padding-left: 30px;
    padding-top: 20px;
    span {
      font-weight: 500;
    }
  }
  .guide-plat-list {
    min-height: 240px;
    display: flex;
    justify-content: flex-start;
    padding: 20px 10px;
    flex-wrap: wrap;
    .plat-item-wrapper {
      padding-top: 5px;
      flex-grow: 1;
      display: flex;
      // justify-content: center;
      flex-direction: column;
      align-items: center;
      @innerWidth: 190px;
      .plat-item {
        height: 110px;
        width: @innerWidth;
        margin: 0 10px;
        box-shadow: 2px 2px 2px 2px rgba(250, 250, 250, 1);
        position: relative;
        padding: 10px 0;
        overflow: hidden;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid #eeeeee;
        cursor: pointer;
        &.active {
          border: 1px solid @primary-color;
        }
        img {
          width: 100%;
          height: 100%;
        }
        .checked-box {
          @boxSize: 50px;
          width: @boxSize;
          height: @boxSize;
          position: absolute;
          background-color: @primary-color;
          z-index: 99;
          bottom: calc(-@boxSize / 2);
          right: calc(-@boxSize / 2);
          transform: rotate(45deg);
          &::after {
            @innerWidth: 16px;
            @innerHeight: 8px;
            content: '';
            position: absolute;
            width: @innerWidth;
            height: @innerHeight;
            box-sizing: border-box;
            color: #fff;
            border-bottom: 2.5px solid;
            border-left: 2.5px solid;
            transform: rotate(-90deg);
            top: 2.5 * @innerHeight;
            // left: @innerHeight / 2;
            border-radius: 0 0 0 3px;
          }
        }
      }
      .guide-form-item {
        display: flex;
        flex-wrap: nowrap;
        width: @innerWidth;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        input {
          width: @innerWidth;
        }
      }
    }
  }
  .edit-plat-container {
    padding: 0 30px;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 10px 0;
      // .plat-select-box{
      // display: flex;
      // }
    }
    .render-plat-list {
      display: flex;
      box-sizing: border-box;
      flex-wrap: wrap;
      padding: 10px 0;
      max-height: 400px;
      overflow-y: auto;
      .plat-item {
        width: 50%;
        height: 120px;
        position: relative;
        display: flex;
        padding: 10px;
        @platItemBac: #fafafa;
        .logo-box {
          width: 37%;
          background: @platItemBac;
          display: flex;
          // justify-content: center;
          padding-left: 15px;
          align-items: center;
          border-radius: 10px 0 0 10px;
          img {
            max-width: 45px;
            max-height: 45px;
            border-radius: 3px;
            margin-right: 5px;
            box-shadow: 2px 2px 6px 0 #ccc;
          }
          & > span {
            color: #000;
            font-size: 15px;
            font-weight: 500;
          }
        }
        .edit-form-item {
          padding: 10px 20px;
          background: @platItemBac;
          flex: 1 auto;
          display: flex;
          height: 100%;
          flex-direction: column;
          border-radius: 0 10px 10px 0;
        }
        .close-box {
          position: absolute;
          cursor: pointer;
          right: 10px;
          top: 10px;
          // color: #fff;
          color: red;
          font-size: 18px;
          width: 30px;
          height: 30px;
          justify-content: center;
          display: flex;
          align-items: center;
          border-radius: 0 10px 0 10px;
          // background-color: red;
          opacity: 0;
          transition: opacity 0.3s;
        }
        &:hover .close-box {
          opacity: 1;
        }
      }
    }
  }
}
</style>
