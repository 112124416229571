import request from '@/utils/request'

const api = {
  usedFirstAggergate: '/user/usedFirstAggergate',
  // 获取对应的广告平台列表
  platForAutoOperator: '/plat/platForAutoOperator',
  platAccountForAutoOperator: '/plat/platAccountForAutoOperator',
  sourceCount: '/aggregate/sourceCount',
  batchAddSource2: '/aggregate/batchAddSource2',
  getTemplateOrAdsouceList: '/aggregate/getTemplateOrAdsouceList',
  setOperatorType: '/aggregate/operatorType',
  postAutoCreatePlat: '/aggregate/autoCreate'
}

export default api

export function usedFirstAggergate (data) {
  return request({
    url: api.usedFirstAggergate,
    method: 'post',
    data
  })
}

export function platForAutoOperator (data) {
  return request({
    url: api.platForAutoOperator,
    method: 'post',
    data
  })
}

export function platAccountForAutoOperator (data) {
  return request({
    url: api.platAccountForAutoOperator,
    method: 'post',
    data
  })
}

export function getSourceCount (data) {
  return request({
    url: api.sourceCount,
    method: 'post',
    data
  })
}

export function getTemplateOrAdsouceList (data) {
  return request({
    url: api.getTemplateOrAdsouceList,
    method: 'post',
    data
  })
}

export function batchAddSource2 (data) {
  return request({
    url: api.batchAddSource2,
    method: 'post',
    data
  })
}

export function setOperatorType (data) {
  return request({
    url: api.setOperatorType,
    method: 'post',
    data
  })
}

export function postAutoCreatePlat (data) {
  return request({
    url: api.postAutoCreatePlat,
    method: 'post',
    data
  })
}
